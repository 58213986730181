
.nav-link.nav-link-sm {
  font-size: small;
  display: block;
  padding: 0.2rem .5rem;
  border-bottom: 1px solid grey;
}
.nav-item > a.nav-link {
  padding:2px;
  font-size: 0.9em;
}
.anchor {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}
.anchor:hover {
    color: #0056b3;
    text-decoration: underline;
}
